import ConfigurationService from "./ConfigurationService";

const MediaService = {

    async uploadMedia(files, shortId) {

		try {
			let endpoint = `${ConfigurationService.resolveApi().rest}/document_request/upload`;
			// let name = file.name;
			// let extension = name.substring(name.lastIndexOf(".") + 1);

			let data = new FormData();

			for (let file of files) {
				data.append("files", file);
			}

			data.append("shortId", shortId);

			let response = await fetch(
				endpoint,
				{
					method: "post",
					body: data,

				},
				30000
			);

			let responseData = await response.json();

			return responseData;
		} catch (error) {
            console.log(`An error occured trying to upload a media item to the platform.`)
			console.log(error);
		}

        return;
	},


};

export default MediaService;
