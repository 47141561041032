const currentEnvironment = "production";

const env = {
    development: {
        rest: "http://localhost:5001/api"
    },
    production: {
        rest: "https://api.getdocify.com/api"
    }
}

const ConfigurationService = {

    /**
     * Returns the environment endpoints set above
     *
     * @return {String}
     */
    resolveApi: () => {
        return env[currentEnvironment];
    }

}

export default ConfigurationService;
