import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import DocumentUpload from './components/document_upload/DocumentUpload.js';
import ThankYou from './components/static_pages/ThankYou.js';
import NotFound from "./components/static_pages/NotFound.js";

function App() {
  return (
    <div className="App">
        <Router>
            <div className="App">
                <Switch>
                    <Route exact path="/thank-you" component={ThankYou} />
                    <Route exact path="/:shortId" component={DocumentUpload} />
                    <Route path="/" component={NotFound} />
                </Switch>
            </div>
        </Router>
    </div>
  );
}

export default App;
