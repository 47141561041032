import React, { Component } from 'react';
import "./documentUpload.css"
// Import React FilePond
import { FilePond } from "react-filepond";
import axios from "axios";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import MediaService from '../../services/MediaService';
import ConfigurationService from '../../services/ConfigurationService';


class DocumentUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            shortId: null,
            loading: true
        };

        this.onSubmitDoc = this.onSubmitDoc.bind(this);
    }

    async componentDidMount() {
        await this.fetchDocumentRequest();
    }

    async fetchDocumentRequest() {
        let shortId = this.props.match.params.shortId;
        let endpoint = `${ConfigurationService.resolveApi().rest}/document_request/${shortId}`;

        try {
            let response = await axios.get(endpoint);

            this.setState({
                shortId: shortId,
                loading: false
            });

        } catch (error) {
            this.props.history.push('/');
        }
    }

    async onSubmitDoc() {
        let { shortId } = this.state;
        this.setState({
            loading: true
        })

        let mediaService = await MediaService.uploadMedia(this.state.files, shortId);

        this.setState({
            loading: false
        }, () => {

            this.props.history.push('/thank-you')
        })
    }

    render() {
        let { loading } = this.state;

        return (
            <div className="DocumentUpload">

                <div className="doc-upload-header-container">
                    <div className="company-logo-container">
                        <img className="company-logo" src={require('./logo.svg')} alt=""></img>
                    </div>
                    <div className="doc-text-container">
                        {!loading && <h1 className="doc-text">Please upload or Drag & Drop your documents below, and your Accountant will be notified.</h1>}
                        {loading && <h1 className="doc-text">Loading ...</h1>}
                    </div>
                </div>

                {!loading && <div className="doc-input-container">
                    <FilePond
                        ref={ref => (this.pond = ref)}
                        files={this.state.files}
                        allowMultiple={true}
                        maxFiles={3}
                        onupdatefiles={fileItems => {
                            // Set currently active file objects to this.state
                            this.setState({
                                files: fileItems.map(fileItem => fileItem.file)
                            });
                        }}
                        />
                </div>}

                {!loading && <div className="doc-upload-button-container">
                    <button className={ this.state.files < 1 ? "doc-upload-button" : "doc-upload-button-active" } onClick={this.onSubmitDoc}>
                        Submit Document
                    </button>
                </div>}
            </div>
        );
    }
}

export default DocumentUpload;
