import React, { Component } from 'react';
import "../document_upload/documentUpload.css"

class ThankYou extends Component {
    render() {
        return (
            <div className="ThankYou DocumentUpload">

                <div className="doc-upload-header-container">
                    <div className="company-logo-container">
                        <img className="company-logo" src={require('../document_upload/logo.svg')} alt=""></img>
                    </div>
                    <div className="doc-text-container">
                        <h1 className="doc-text">Thank you very much on behalf of SRJ Chartered Accounts, your Staff Accountant has been notified!</h1>
                    </div>
                </div>

            </div>
        );
    }
}

export default ThankYou;
